
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.hero {
  display: flex;
  align-items: center;
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    height: auto;
    aspect-ratio: 16/9;
  }
  h1 {
    z-index: 2;
    position: relative;
    color: #d4f593;
    font-size: 50px;
    line-height: 55px;
    font-weight: 400;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 106px;
      line-height: 108px;
    }
    br {
      display: none;
      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        display: block;
      }
    }
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    height: 60vh;
    // width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      height: 100%;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60vh;
    aspect-ratio: 16/9;
    overflow: hidden;
    background: url('/halogenImg/overlay-cropped.png');
    background-size: cover;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      height: 100%;
    }
  }
}
