
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.carousel {
  padding: 60px 0;
  &.greenSlider {
    padding-top: 0;
    .sliderWrapper {
      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        padding-top: 12px;
      }
    }
  }
  .sliderWrapper {
    padding: 50px 20px;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle, #0c2a31 0%, #000000 80%);
      opacity: 0.5;
      border-radius: 16px;
    }
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 100px 20px;
      border-radius: 16px;
    }

    div[class*='slick-track'] {
      display: flex;
      align-items: center;
    }

    .slideWrapper {
      width: 100%;
      max-width: 1050px;
      color: #fff;
      align-items: center;
      justify-content: start;
      margin: 0 auto;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        display: flex !important;
      }
      h2 {
        @media (max-width: map-get($grid-breakpoints, 'lg')) {
          font-size: 31px;
          line-height: 38.75px;
        }
      }
      .circleNum {
        background-color: #fff;
        padding: 10px 30px;
        border-radius: 100%;
        color: #02575c;
        width: 32px;
        height: 58px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-bottom: 20px;
        align-self: center;
        .num {
          font-size: 23px;
          font-weight: 800;
          line-height: 0;
        }
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          width: 142px;
          height: 142px;
          padding: 20px 40px;
          margin-bottom: 0;
          .num {
            font-size: 63px;
          }
        }
      }
    }
    ul[class*='slick-dots'] {
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        bottom: -75px;
      }
      li {
        margin: 0 10px;
      }
      li button:before {
        color: #22b9c5;
        font-size: 20px;
        opacity: 1;
      }
      li[class*='slick-active'] {
        button:before {
          color: #fff;
          font-size: 20px;
          opacity: 1;
        }
      }
    }
  }
}
