
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.imageText {
  padding: 10px;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    padding: 100px 0 120px 0;
  }
  img {
    border-radius: 30px;
  }
  .content {
    gap: 50px;
    display: flex;
    flex-direction: column;
  }
  .imgWrapper {
    position: relative;
    overflow: hidden;
    width: fit-content;
    height: auto;
    border-radius: 30px;
    overflow: hidden;
    background-color: #d4f593;
  }
  video {
    border-radius: 16px;
  }
}
