
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.form {
  padding: 120px 0;
  h3 {
    font-size: 26px;
    line-height: 121%;
    letter-spacing: -0.7;
    color: #0c2a31;
    font-weight: 600;
    text-align: center;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 39px;
    }
  }
  form {
    margin: 0 auto;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .name {
      display: flex;
      gap: 40px;
      flex-direction: column;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        flex-direction: row;
        gap: 10px;
      }
      input {
        flex: 1;
      }
    }
    input {
      border: none;
      border-bottom: 1px solid #0c2a31;
      font-size: 22px;
      outline: none;
      &::placeholder {
        font-size: 22px;
        color: #0c2a31;
      }
      -webkit-background-clip: text;
    }
    .submitBtn {
      font-family: var(--root-font);
      padding: 12px 22px;
      border-radius: 60px;
      background: #d4f593;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: none;
      font-size: 16px;
      color: #02575c;
      font-weight: 600;
      margin: auto;
      width: max-content;
      cursor: pointer;
      &:hover {
        background-color: #22b9c5;
        color: #fff;
      }
    }
  }
}
