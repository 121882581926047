
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.slider {
  padding: 70px 0;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    padding-top: 150px;
  }

  .sliderWrapper {
    background-color: #148893;
    border-radius: 16px;
    color: #fff;
    padding: 45px;
    p {
      font-size: 38px;
      line-height: 127%;
      span {
        text-decoration: underline;
      }
    }

    ul[class*='slick-dots'] {
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        bottom: -30px;
      }
      li {
        margin: 0 10px;
      }
      li button:before {
        color: #0c2a31;
        font-size: 20px;
        opacity: 1;
      }
      li[class*='slick-active'] {
        button:before {
          color: #fff;
          font-size: 20px;
          opacity: 1;
        }
      }
    }
  }

  .iconWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-top: 80px;
    }
    svg {
      margin: 10px;
    }
    .thumbsUp {
      margin: 10px 4px 10px 16px;
    }
    .iconText {
      display: flex;
      flex-direction: column;
      align-items: center;
      .textIcon {
        color: #148893;
        font-size: 17px;
        line-height: 31px;
        font-weight: 900;
        font-family: var(--baloo-font);
        margin-top: 10px;
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 26px;
        }
      }
    }
  }
  .svgWrapper {
    border-radius: 100%;
    padding: 5px 8px;
    svg {
      width: 40px;
      height: 40px;
      cursor: pointer;
      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        width: 70px;
        height: 70px;
      }
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        width: 60px;
        height: 60px;
      }
      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        width: 70px;
        height: 70px;
      }
    }
    &.activeIcon {
      background-color: #148893;

      svg {
        path {
          fill: #d4f593 !important;
          stroke: #d4f593 !important;
          background-color: aqua;
        }
      }
    }
  }
}
